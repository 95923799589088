import { Params, useParams, useSearchParams } from "react-router-dom"

export const useJoinUrlParams = () => {
  const [searchParams] = useSearchParams()
  const params = useParams()
  return parseJoinUrl({ url: window.location.href, searchParams, params })
}

export const parseJoinUrl = ({
  url,
  searchParams,
  params,
}: {
  url: string
  searchParams: URLSearchParams
  params: Readonly<Params<string>>
}) => {
  /*
   * We must support pulling the token from the /join/:token params for one
   * release to ensure backward compatiblity with the server. After that,
   * we can remove the code that looks for params.guestToken embedded
   * within the url path.
   */
  const isNewFormat = searchParams.has("g")

  return {
    urlToValidate: filterSearchParams(url, ["participantName"]),
    guestToken: isNewFormat
      ? searchParams.get("g") ?? undefined
      : params.guestToken,
    region: searchParams.get("r")?.toLowerCase() ?? undefined,
    participantName: searchParams.get("participantName") ?? undefined,
  }
}

export const filterSearchParams = (url: string, removeParamNames: string[]) => {
  const result = new URL(url)
  removeParamNames.forEach((name) => result.searchParams.delete(name))
  return result.toString()
}
