import { useEffect, useState } from "react"
import { joinCall, deleteAttendance, deleteAttendanceSync } from "../../api"
import { CommClientJoinCallParams } from "../../api/mapping/types"
import { getCommClientJoinCallParams } from "../../api/mapping"
import { HttpStatusCode, StatusCodeError } from "../../api/statusCodeError"
import { getAttendeeToken } from "../stores/commClientInitParams"
import { getGuestToken } from "../stores/guestToken"
import { isFirefox } from "@teladoc/browser/browser"
import { createEvent, createStore } from "effector"
import { ErrorReason, ErrorReasonValues } from "../ErrorReason"

export const useGuestJoin = () => {
  const [joinCallParams, setJoinCallParams] =
    useState<CommClientJoinCallParams>()
  const [joinError, setJoinError] = useState<ErrorReasonValues | undefined>()
  const attendeeId = joinCallParams?.adapterPayload.attendee_id
  const attendanceId = joinCallParams?.adapterPayload.attendance_id

  useEffect(() => {
    const attendeeToken = getAttendeeToken()
    const guestToken = getGuestToken()

    const joinCallWithRetries = (retriesRemaining: number) => {
      joinCall(attendeeToken, guestToken)
        .then(getCommClientJoinCallParams)
        .then(setJoinCallParams)
        .then(joinedCall)
        .catch((err) => {
          if (isAlreadyJoinedError(err) && retriesRemaining > 0) {
            setTimeout(() => joinCallWithRetries(retriesRemaining - 1), 2000)
          } else if (hasConferenceEndedError(err)) {
            setJoinError(ErrorReason.CONFERENCE_ENDED)
          } else if (isAlreadyJoinedError(err)) {
            setJoinError(ErrorReason.ATTENDEE_ALREADY_JOINED)
          } else {
            setJoinError(ErrorReason.UNHANDLED)
          }
        })
    }

    joinCallWithRetries(3)
  }, [])

  /*
   * Clean up attendance on component unmount (back/forward page nav)
   */
  useEffect(() => {
    if (!attendeeId) return
    if (!attendanceId) return
    const attendeeToken = getAttendeeToken()
    return () => {
      leaveCall({ attendeeToken, attendeeId, attendanceId })
    }
  }, [attendeeId, attendanceId])

  /*
   * Clean up attendance on page unload (browser refresh/close)
   */
  useEffect(() => {
    if (!attendeeId) return // only leave if we successfully joined
    if (!attendanceId) return
    const attendeeToken = getAttendeeToken()
    const onUnload = () =>
      onPageUnload({ attendeeToken, attendeeId, attendanceId })
    window.addEventListener("unload", onUnload)
    return () => {
      window.removeEventListener("unload", onUnload)
    }
  }, [attendeeId, attendanceId])

  return { joinCallParams, joinError }
}

export const isAlreadyJoinedError = (error: unknown) =>
  error instanceof StatusCodeError &&
  error.getStatusCode() === HttpStatusCode.UNPROCESSABLE_ENTITY

export const hasConferenceEndedError = (error: unknown) =>
  error instanceof StatusCodeError &&
  error.getStatusCode() === HttpStatusCode.GONE

type AttendanceProps = {
  attendeeToken: string
  attendeeId: number
  attendanceId: number
}

const onPageUnload = (attendanceProps: AttendanceProps) => {
  if (isFirefox()) {
    // this should be removed once Firefox supports fetch() with keepalive
    deleteAttendanceSync(attendanceProps)
  } else {
    deleteAttendance({ ...attendanceProps, keepalive: true })
  }
}

const joinedCall = createEvent()

const leavingCall = createEvent()

const $hasStartedLeaving = createStore<boolean>(false)
  .on(leavingCall, () => true)
  .reset(joinedCall)

export const leaveCall = async (attendanceProps: AttendanceProps) => {
  if ($hasStartedLeaving.getState()) return
  leavingCall()
  try {
    await deleteAttendance(attendanceProps)
  } catch (err) {
    const isUserAlreadyLeft =
      err instanceof StatusCodeError && err.getStatusCode() === 422
    if (!isUserAlreadyLeft) {
      console.error(err) // eslint-disable-line no-console
      throw err
    }
  }
}
