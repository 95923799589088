import { createEffect, createStore } from "effector"
import { getSharedCommClient, initUCS } from "../../ucs"
import type {
  CommClientConfiguration,
  ICommClient,
} from "@teladoc/ucs-client-react"
import { useStore } from "effector-react"
import { useEffect } from "react"

const loadCommClient = createEffect({
  async handler(config: CommClientConfiguration) {
    await initUCS(config)
    return getSharedCommClient()
  },
})
export const commClientStore = createStore<ICommClient | null>(null).on(
  loadCommClient.done,
  (_, data) => data.result
)

export const useCommClient = (config?: CommClientConfiguration) => {
  const commClient = useStore(commClientStore)

  useEffect(() => {
    if (!commClient && config) {
      loadCommClient(config)
    }
  }, [commClient, config])

  return { commClient }
}
