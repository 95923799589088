import { useEffect } from "react"
import styled from "@emotion/styled"
import { useCommClient } from "./hooks/useCommClient"
import { useUrlValidation } from "./hooks/useUrlValidation"
import { PageContainer } from "./PageContainer"
import { usePageNavigation } from "./hooks/usePageNagivation"
import { N900, P800 } from "../assets/colors"
import { guestTokenStore } from "./stores/guestToken"
import { Loader } from "../components/Loader"
import { updateGuestName } from "../api"
import { useJoinUrlParams } from "./hooks/useJoinUrlParams"
import { useStore } from "effector-react"

export const ValidationPage = () => {
  const guestToken = useStore(guestTokenStore)
  const { participantName, urlToValidate } = useJoinUrlParams()
  const { commClientInitParams } = useUrlValidation(urlToValidate)
  const { commClient } = useCommClient(commClientInitParams?.environment)
  const nav = usePageNavigation("landing")
  const isLoaded = Boolean(guestToken && nav && commClient)

  useEffect(() => {
    if (!commClientInitParams || !guestToken) return

    const onPageLoaded = async () => {
      const {
        attendee: { attendee_token },
      } = commClientInitParams

      if (participantName) {
        await updateGuestName({
          guestToken,
          guestName: participantName,
          attendeeToken: attendee_token,
        })
      }
    }

    if (isLoaded) {
      onPageLoaded().then(nav.goToNextPage).catch(nav.goToErrorPage)
    }
  }, [isLoaded, participantName, commClientInitParams, guestToken])

  return (
    <PageContainer>
      <Container>
        <HeaderContainer>
          <div>We're taking you to the visit</div>
        </HeaderContainer>
        <ItemContainer>
          <Loader size="large" color={P800} />
        </ItemContainer>
        <DetailsContainer>
          It can take a few moments to get you connected.
        </DetailsContainer>
      </Container>
    </PageContainer>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 55vh;
`
const HeaderContainer = styled.div`
  font-weight: 700;
  display: flex;
  color: ${N900};
  margin-top: 1.75rem;
  align-items: center;
  font-weight: 700;
  text-align: center;
  font-size: 2.313rem;
  line-height: 2.5rem;
`

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
`

const DetailsContainer = styled(ItemContainer)`
  font-size: 1.125rem;
  line-height: 1.75rem;
`
