/** Neutral colors **/
export const N900 = "#181919"
export const N700 = "#565757"
export const N200 = "#DFE0DF"
export const N400 = "#6F6F6F"
/** Primary colors **/
export const P800 = "#5b2f91"
/** Secondary colors **/
export const S600 = "#5156F5"
/** Critical colors **/
export const C600 = "#CD000A"
