export const HttpStatusCode = {
  GONE: 410,
  BAD_REQUEST: 400,
  UNPROCESSABLE_ENTITY: 422,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  NOT_FOUND: 404,
}

export class StatusCodeError extends Error {
  #statusCode: number

  constructor(statusCode: number, message?: string) {
    super(message)
    this.#statusCode = statusCode
  }

  getStatusCode = () => this.#statusCode
}
