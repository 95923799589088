import { getCommClientInitParams } from "../../api/mapping"
import { useStore } from "effector-react"
import { useEffect } from "react"
import { validateUrl } from "../../api"
import { useNavigate } from "react-router-dom"
import { StatusCodeError, HttpStatusCode } from "../../api/statusCodeError"
import {
  commClientInitParamsStore,
  setCommClientInitParams,
} from "../stores/commClientInitParams"
import { ErrorReason } from "../ErrorReason"

export const useUrlValidation = (url?: string) => {
  const commClientInitParams = useStore(commClientInitParamsStore)
  const navigate = useNavigate()

  useEffect(() => {
    if (!commClientInitParams) {
      const performUrlValidation = async () => {
        try {
          if (!url) throw new Error("url is required")
          const validateResponse = await validateUrl(url)
          const commClientInitParams = getCommClientInitParams(validateResponse)
          setCommClientInitParams(commClientInitParams)
        } catch (error) {
          const { GONE, BAD_REQUEST, UNPROCESSABLE_ENTITY, NOT_FOUND } =
            HttpStatusCode
          if (error instanceof StatusCodeError) {
            const errorCode = error.getStatusCode()
            if (errorCode === GONE) {
              navigate("/meetingEnded")
            } else if (
              errorCode === BAD_REQUEST ||
              errorCode === UNPROCESSABLE_ENTITY ||
              errorCode === NOT_FOUND
            ) {
              navigate("/invalidUrl")
            } else {
              throw error
            }
          } else {
            throw error
          }
        }
      }
      performUrlValidation().catch((e) => {
        console.error(e) // eslint-disable-line no-console
        navigate(`/error/${ErrorReason.UNHANDLED}`)
      })
    }
  }, [commClientInitParams])
  return { commClientInitParams }
}
