import { createEvent, createStore } from "effector"
import { CommClientInitParams } from "../../api/mapping/types"
import {
  getInitParamsFromSessionStorage,
  removeInitParamsFromSessionStorage,
  setInitParamsToSessionStorage,
} from "./sessionStorage"
import { clearStores } from "./clearStores"

export const setCommClientInitParams = createEvent<CommClientInitParams>()

export const commClientInitParamsStore =
  createStore<CommClientInitParams | null>(getInitParamsFromSessionStorage())
    .on(setCommClientInitParams, (_, value) => {
      setInitParamsToSessionStorage(value)
      return value
    })
    .on(clearStores, () => {
      removeInitParamsFromSessionStorage()
      return null
    })

export const getAttendeeToken = () => {
  const initParams = commClientInitParamsStore.getState()
  const { attendee_token: attendeeToken } = initParams?.attendee || {}
  if (!attendeeToken) throw new Error("attendee token is not valid")
  return attendeeToken
}
