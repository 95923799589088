import type {
  GuestJoinResponse,
  PreCallTestResponse,
  ValidateUrlResponse,
} from "../types"
import type {
  CommClientInitParams,
  CommClientJoinCallParams,
  IPreCallTestAdapterPayload,
} from "./types"

const replaceNull = <T>(value?: T | null | undefined) =>
  value === null ? undefined : value

export const getCommClientInitParams = (
  validateUrlResponse: ValidateUrlResponse
): CommClientInitParams => {
  const {
    environment: {
      app_name,
      app_version,
      app_role,
      app_environment,
      app_platform,
      app_module_keys,
      proxy_urls,
      endpoints,
    },
    attendee: { attendee_token },
  } = validateUrlResponse.data

  return {
    environment: {
      app_name,
      app_version,
      app_role,
      app_environment,
      app_platform,
      app_module_keys: {
        watchrtc: replaceNull(app_module_keys.watchrtc),
      },
      proxy_urls: {
        watchrtc: replaceNull(proxy_urls?.watchrtc),
      },
      endpoints,
    },
    attendee: {
      attendee_token,
    },
  }
}

export const getCommClientPreCallPayload = (
  preCallResponse: PreCallTestResponse
): IPreCallTestAdapterPayload => {
  const {
    precall_test_id: pre_call_test_id,
    auth_details: auth_data,
    auth_details: { video_provider },
  } = preCallResponse.data

  return { [video_provider]: { pre_call_test_id, auth_data } }
}

export const getCommClientJoinCallParams = (
  guestJoinResponse: GuestJoinResponse
): CommClientJoinCallParams => {
  const { attendance } = guestJoinResponse.data
  const { attendee_id, attendee_token, conference_id } = attendance

  return {
    adapterPayload: attendance,
    sessionJoinParams: {
      attendeeToken: attendee_token,
      attendeeId: attendee_id,
      conferenceId: conference_id,
    },
  } as CommClientJoinCallParams
}
