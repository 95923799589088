import { Global, css } from "@emotion/react"
import EffraRegular from "./fonts/Effra_W_Rg.woff2"
import EffraMedium from "./fonts/Effra_W_Md.woff2"
import EffraBold from "./fonts/Effra_W_Bd.woff2"

export const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: "Effra";
          font-style: normal;
          src: url(${EffraRegular});
          font-weight: 500;
        }
        @font-face {
          font-family: "Effra";
          font-style: normal;
          src: url(${EffraMedium});
          font-weight: 600;
        }
        @font-face {
          font-family: "Effra";
          font-style: normal;
          src: url(${EffraBold});
          font-weight: 700;
        }
        html,
        body,
        button {
          font-family: "Effra";
        }
      `}
    />
  )
}
