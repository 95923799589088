import { useState } from "react"
import { useStore } from "effector-react"
import styled from "@emotion/styled"
import { PureInput } from "@teladoc/ui-components/input"
import { PageContainer } from "./PageContainer"
import { C600, N900 } from "../assets/colors"
import { ErrorIcon } from "../assets/icons/ErrorIcon"
import { Button } from "../components/Button"
import { updateGuestName } from "../api"
import { usePageNavigation } from "./hooks/usePageNagivation"
import { useUrlValidation } from "./hooks/useUrlValidation"
import { guestTokenStore } from "./stores/guestToken"
import { StatusCodeError, HttpStatusCode } from "../api/statusCodeError"

const INAPPROPRIATE_NAME_ERROR = (
  <span>
    Some words deemed <i>profane</i> or <i>inappropriate</i> are not allowed
  </span>
)
const GENERIC_ERROR = "Something went wrong. Try again"
const MISSING_NAME_ERROR = "Your name is required"

export const WelcomePage = () => {
  const [name, setName] = useState("")
  const [errorMessage, setErrorMessage] = useState<JSX.Element | string>()
  const [isLoading, setLoading] = useState(false)

  const { commClientInitParams } = useUrlValidation()
  const guestToken = useStore(guestTokenStore)

  const { goToNextPage } = usePageNavigation("welcome")

  const tryValidateAndSubmit = async () => {
    try {
      await validateAndSubmit()
    } catch (error) {
      setLoading(false)
      handleError(error)
    }
  }

  const validateAndSubmit = async () => {
    const guestName = name.trim()
    if (!guestName) {
      setErrorMessage(MISSING_NAME_ERROR)
    } else {
      setErrorMessage("")
      setLoading(true)
      if (!commClientInitParams) throw new Error("Comm client not initialized")
      if (!guestToken) throw new Error("guest token is invalid or missing")
      const {
        attendee: { attendee_token },
      } = commClientInitParams
      await updateGuestName({
        guestToken,
        guestName,
        attendeeToken: attendee_token,
      })
      goToNextPage()
    }
  }

  const handleError = (error: unknown) =>
    setErrorMessage(
      error instanceof StatusCodeError &&
        error.getStatusCode() === HttpStatusCode.UNAVAILABLE_FOR_LEGAL_REASONS
        ? INAPPROPRIATE_NAME_ERROR
        : GENERIC_ERROR
    )

  return (
    <PageContainer>
      <Container>
        <HeaderContainer>Join the virtual visit</HeaderContainer>
        <FormContainer>
          <InputLabel hasError={!!errorMessage}>
            <strong>Enter your name</strong>
          </InputLabel>
          <InputDetailsLabel>
            Your name will display to everyone participating in the visit
          </InputDetailsLabel>
          <Input
            onChange={setName}
            disabled={isLoading}
            value={name}
            ariaLabel="Enter your name"
          />
          {errorMessage && (
            <ErrorContainer>
              <ErrorIconContainer>
                <ErrorIcon />
              </ErrorIconContainer>
              <InputDetailsLabel>{errorMessage}</InputDetailsLabel>
            </ErrorContainer>
          )}
          <Button
            style={{ marginTop: "1rem" }}
            onClick={tryValidateAndSubmit}
            isLoading={isLoading}
          >
            Continue
          </Button>
        </FormContainer>
      </Container>
    </PageContainer>
  )
}

const InputLabel = styled.div<{ hasError?: boolean }>`
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.25rem;
  padding-bottom: 0.25rem;
  color: ${(props) => (props.hasError ? `${C600}` : "initial")};
`

const FormContainer = styled.div`
  height: 35vh;
`

const InputDetailsLabel = styled.div`
  font-weight: 400;
  font-size: 0.938rem;
  line-height: 1.25rem;
  padding-bottom: 0.5rem;
`

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 0.25rem;
`

const ErrorIconContainer = styled.div`
  padding: 0.15rem 0.3rem 0 0;
`

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 1rem;
  height: 55vh;
`
const HeaderContainer = styled.div`
  color: ${N900};
  margin-top: 1.75rem;
  font-weight: 700;
  font-size: 2.313rem;
  line-height: 2.5rem;
`

const Input = styled(PureInput)`
  font-size: 1.125rem;
`
