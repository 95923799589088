import { useStore } from "effector-react"
import { useEffect, useState } from "react"
import { isValidRegion } from "./urlsByRegion"
import { $configuredRegion, setConfiguredRegion } from "./configuredRegion"

type Props = {
  preferredRegion?: string
  onInvalidRegion: () => void
}

export const useRegionInitialization = ({
  preferredRegion,
  onInvalidRegion,
}: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const configuredRegion = useStore($configuredRegion)

  useEffect(() => {
    if (preferredRegion) {
      if (isValidRegion(preferredRegion)) {
        setConfiguredRegion(preferredRegion)
      } else {
        setConfiguredRegion(null)
        onInvalidRegion()
      }
    } else {
      setConfiguredRegion("ga")
    }
    setIsInitialized(true)
  }, [preferredRegion])

  return { configuredRegion: isInitialized ? configuredRegion : null }
}
