import { useEffect, useState } from "react"
import { useStore } from "effector-react"
import styled from "@emotion/styled"
import { PageContainer } from "./PageContainer"
import { Button } from "../components/Button"
import { usePageNavigation } from "./hooks/usePageNagivation"
import { preCallPayloadStore } from "./preCheckHandlers/preCallTestPreCheckHandler"
import { PreCallTest } from "../ucs"
import { useUrlValidation } from "./hooks/useUrlValidation"
import { useCommClient } from "./hooks/useCommClient"
import { N900 } from "../assets/colors"
import { updatePreCallTestResult } from "../api"
import { getAttendeeToken } from "./stores/commClientInitParams"

export const PreCallTestPage = () => {
  const [isLoading, setLoading] = useState(false)
  const [isReady, setReady] = useState(false)
  const [isSuccessfulTest, setSuccessfulTest] = useState(false)
  const [isTestComplete, setTestComplete] = useState(false)

  const { commClientInitParams } = useUrlValidation()
  const { commClient } = useCommClient(commClientInitParams?.environment)

  const commClientPreCallPayload = useStore(preCallPayloadStore)

  const { goToNextPage } = usePageNavigation("precall")

  useEffect(() => {
    if (commClient && commClientPreCallPayload) {
      setReady(true)
    }
  }, [commClient, commClientPreCallPayload])

  const onContinue = async () => {
    setLoading(true)
    try {
      await updateTestResult()
    } finally {
      goToNextPage()
    }
  }

  const updateTestResult = async () => {
    const preCallTestId =
      commClientPreCallPayload?.tokbox?.pre_call_test_id ||
      commClientPreCallPayload?.acs?.pre_call_test_id
    await updatePreCallTestResult({
      attendeeToken: getAttendeeToken(),
      preCallTestId: preCallTestId!,
      result: isSuccessfulTest ? "pass" : "fail",
    })
  }

  return isReady ? (
    <PageContainer
      buttonContainer={
        <ButtonContainer>
          <Button
            onClick={onContinue}
            disabled={!isTestComplete}
            isLoading={isLoading}
          >
            Enter visit
          </Button>
        </ButtonContainer>
      }
    >
      <MainContainer>
        <ScrollingContainer>
          <HeaderContainer>Test connection</HeaderContainer>
          <LongDetailsContainer>
            Please allow your browser to access your WebCam and Microphone. If
            you do not see this prompt or a video preview of yourself, you may
            need to check your browser settings.
          </LongDetailsContainer>
          <ShortDetailsContainer>
            Please wait while we test your connection...
          </ShortDetailsContainer>
          <PreCallTest
            runNetworkDiagnosticOnLoad
            preCallTestAdapterPayload={commClientPreCallPayload!}
            onTestComplete={(wasSuccessful: boolean) => {
              setSuccessfulTest(wasSuccessful)
              setTestComplete(true)
            }}
          />
        </ScrollingContainer>
      </MainContainer>
    </PageContainer>
  ) : (
    <></>
  )
}

const ButtonContainer = styled.div`
  align-self: center;
  padding: 0.25rem 0 0.25rem 0;
  @media only screen and (max-width: 415px) {
    width: 98%;
  }
`

const MainContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  height: 100%;
`
const ScrollingContainer = styled.div`
  display: flex;
  width: 98vw;
  align-items: center;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
  justify-content: flex-start;
`

const HeaderContainer = styled.div`
  font-weight: 700;
  display: flex;
  color: ${N900};
  margin-top: 1rem;
  align-items: center;
  font-weight: 700;
  text-align: center;
  font-size: 2.313rem;
  line-height: 2.5rem;
`
const DetailsContainer = styled.div`
  display: flex;
  text-align: center;
  font-size: 1.125rem;
  width: 54vw;
  min-width: 20rem;
  line-height: 1.75rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
`
const LongDetailsContainer = styled(DetailsContainer)`
  display: block;
  @media (min-width: 1px) and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) {
    display: block;
  }
`

const ShortDetailsContainer = styled(DetailsContainer)`
  display: none;
  @media (min-width: 1px) and (max-width: 767px) {
    display: block;
  }
  @media (min-width: 768px) {
    display: none;
  }
`
