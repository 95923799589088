import { useEffect } from "react"
import styled from "@emotion/styled"
import { PageContainer } from "./PageContainer"
import { N900 } from "../assets/colors"
import { clearStores } from "./stores/clearStores"

export const ThankYouPage = () => {
  useEffect(() => {
    clearStores()
  }, [])

  return (
    <PageContainer>
      <Container>
        <PageTitle>The call has ended</PageTitle>
        <PageContent>You may close the tab</PageContent>
      </Container>
    </PageContainer>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: top;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
`
const PageTitle = styled.div`
  color: ${N900};
  margin-top: 1.75rem;
  align-items: center;
  font-weight: 700;
  justify-content: center;
  font-size: 2.3125rem;
  line-height: 2.5rem;
  font-style: normal;
  display: flex;
`
const PageContent = styled.div`
  color: ${N900};
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  margin-top: 1.5rem;
  font-style: normal;
`
