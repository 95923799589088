import { UnsupportedWarning } from "../ucs"
import { PageContainer } from "./PageContainer"
import styled from "@emotion/styled"
import { useWhiteLabelConfig } from "./stores/startupConfiguration"

type Props = {
  onContinueClick: () => void
}

export const UnsupportedPage = ({ onContinueClick }: Props) => {
  const { primary_color, button_text_color } = useWhiteLabelConfig()
  const buttonStyling = {
    continueButtonStyle: {
      backgroundColor: primary_color,
      color: button_text_color,
    },
  }
  return (
    <PageContainer>
      <WarningContainer>
        <UnsupportedWarning
          onContinueClick={onContinueClick}
          {...buttonStyling}
        />
      </WarningContainer>
    </PageContainer>
  )
}

const WarningContainer = styled.div`
  padding: 0 1.5rem;
`
