import { useEffect, useState } from "react"
import { UnsupportedPage } from "./UnsupportedPage"
import { ValidationPage } from "./ValidationPage"
import { FullPageLoader } from "../components/Loader"
import { useStartupConfiguration } from "./hooks/useStartupConfiguration"
import { guestTokenStore, setGuestToken } from "./stores/guestToken"
import { useStore } from "effector-react"
import { usePageNavigation } from "./hooks/usePageNagivation"
import { useUnsupportedWarning } from "./hooks/useUnsupportedWarning"
import { useRegionInitialization } from "../regions"
import { useJoinUrlParams } from "./hooks/useJoinUrlParams"

export const LandingPage = () => {
  const { goToErrorPage, goToInvalidUrlPage } = usePageNavigation("landing")
  const { guestToken, region } = useJoinUrlParams()

  const { configuredRegion } = useRegionInitialization({
    preferredRegion: region ?? undefined,
    onInvalidRegion: goToInvalidUrlPage,
  })
  const isRegionConfigured = !!configuredRegion

  const { startupConfiguration } = useStartupConfiguration({
    isReady: isRegionConfigured,
    onError: (_err) => goToErrorPage(),
  })
  const { isSupported, isError } = useUnsupportedWarning()
  const [isWarningAcknowledged, setWarningAcknowledged] = useState(false)
  const storedGuestToken = useStore(guestTokenStore)

  useEffect(() => {
    if (!guestToken) throw new Error("guest token is required")
    setGuestToken(guestToken)
  }, [guestToken])

  useEffect(() => {
    if (isError) {
      throw new Error("Unable to detect if browser is supported")
    }
  }, [isError])

  const isLoaded =
    Boolean(startupConfiguration) &&
    Boolean(storedGuestToken) &&
    isSupported !== undefined &&
    isRegionConfigured

  return !isLoaded ? (
    <FullPageLoader size="large" color="#000" />
  ) : isSupported || isWarningAcknowledged ? (
    <ValidationPage />
  ) : (
    <UnsupportedPage onContinueClick={() => setWarningAcknowledged(true)} />
  )
}
