import { createEvent, createStore } from "effector"
import {
  getStartupConfigurationFromSessionStorage,
  setStartupConfigurationToSessionStorage,
} from "./sessionStorage"
import { clearStores } from "./clearStores"
import type { ApiUIMode } from "../../api/types"
import { applyDefaultWhiteLabelConfig } from "./startupConfigurationDefaults"
import { useStore } from "effector-react"

export type UIProfile = {
  ui_mode: ApiUIMode
  enable_status_events: boolean
  background_replacement_color: string | undefined
  offer_precall_test: boolean
  collect_user_name: boolean
  block_video_quality_survey: boolean
}

export type StartupOptions = {
  skip_browser_check: boolean
  monitor_admin_presence: boolean
  admin_reconnect_grace_period: number
}

export type WhiteLabelConfig = {
  logo_url?: string
  primary_color: string
  button_text_color: string
}

export type StartupConfiguration = {
  startup_options: StartupOptions
  ui_profile: UIProfile
  white_label_config: WhiteLabelConfig
}

export const setStartupConfiguration = createEvent<StartupConfiguration>()

export const $startupConfigurationStore =
  createStore<StartupConfiguration | null>(
    getStartupConfigurationFromSessionStorage()
  )
    .on(setStartupConfiguration, (_, value) => {
      setStartupConfigurationToSessionStorage(value)
      return value
    })
    .on(clearStores, () => {
      /*
       * No-op.  We're intentionally leaving this configuration in storage for
       * this browser session.  For example, if someone refreshes the browser
       * on the "Thank you" page, we want to retain their white-labeling config.
       */
    })

export const getStartupConfiguration = () => {
  const startupConfiguration = $startupConfigurationStore.getState()
  if (!startupConfiguration) throw new Error("Startup configuration not found")
  return startupConfiguration
}

export const getUiProfile = () => {
  const { ui_profile } = getStartupConfiguration()
  return ui_profile
}

const getDefaultWhiteLabelConfig = () => applyDefaultWhiteLabelConfig({})

const $whiteLabelConfig = $startupConfigurationStore.map(
  (config) => config?.white_label_config ?? getDefaultWhiteLabelConfig()
)

export const useWhiteLabelConfig = () => useStore($whiteLabelConfig)
