import styled from "@emotion/styled"
import { TeladocLogo } from "../assets/icons/TeladocLogo"
import { useWhiteLabelConfig } from "./stores/startupConfiguration"

export const Logo = () => {
  const { logo_url } = useWhiteLabelConfig()
  return (
    <Container>
      {logo_url ? (
        <CustomImage src={logo_url} />
      ) : (
        <TeladocLogo width="100%" height="100%" />
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 130px;
  height: 32px;
`

const CustomImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
