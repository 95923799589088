import styled from "@emotion/styled"
import { PageContainer } from "./PageContainer"
import { N900, S600 } from "../assets/colors"
import { useParams } from "react-router-dom"
import { Button } from "../components/Button"
import { useEffect, useState } from "react"
import { useStore } from "effector-react"
import { guestTokenStore } from "./stores/guestToken"
import { ErrorReason } from "./ErrorReason"
import { useLanguageLine } from "./languageLine"

type ErrorMapType = {
  code: string
  reason?: string
  details?: string
}

const errorMap: ErrorMapType[] = [
  {
    code: ErrorReason.ATTENDEE_ALREADY_JOINED,
    reason: "You've already joined the call",
    details:
      "You're attending the virtual visit on another device or browser. To join on this device, leave that visit and try joining the visit again.",
  },
]

export const ErrorPage = () => {
  const [error, setError] = useState<ErrorMapType>()
  const { reasonCode } = useParams()
  const { onSessionConnectFailed } = useLanguageLine()

  useEffect(() => {
    const error = errorMap.find((r) => r.code === reasonCode)
    if (error) {
      setError(error)
    }
    const reasonStr = error ? error.reason : undefined
    onSessionConnectFailed(undefined, reasonStr)
  }, [reasonCode])

  const guestToken = useStore(guestTokenStore)

  return (
    <PageContainer>
      <Container>
        <HeadingContainer>{"Something went wrong"}</HeadingContainer>
        {error?.reason && (
          <SubHeadingContainer>{error.reason}</SubHeadingContainer>
        )}
        <DetailsContainer>
          {error?.details || (
            <div>
              Try refreshing the page or restarting your browser. If this
              doesn’t work, please contact us at &nbsp;
              <Link href="tel:+18008352362">1 (800) 835-2362</Link>
            </div>
          )}
        </DetailsContainer>
        <Button
          onClick={() => {
            window.location.href = `/join/${guestToken}`
          }}
        >
          Try again
        </Button>
      </Container>
    </PageContainer>
  )
}

const Container = styled.div`
  padding-top: 2rem;
  display: flex;
  justify-content: top;
  align-items: center;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: 28rem;
`
const HeadingContainer = styled.div`
  font-weight: 700;
  display: flex;
  color: ${N900};
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  align-self: flex-start;
  font-size: 2.313rem;
  line-height: 2.5rem;
`
const Link = styled.a`
  white-space: nowrap;
  text-decoration: underline;
  text-underline-position: under;
  padding: 1.25rem 0 0 0;
  font-size: 1.125rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: ${S600};
  font-weight: 500;
`

const SubHeadingContainer = styled.div`
  display: flex;
  color: ${N900};
  align-items: center;
  font-weight: 500;
  align-self: flex-start;
  font-size: 1.625rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
`

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: top;
  text-align: left;
`

const DetailsContainer = styled(ItemContainer)`
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
`
