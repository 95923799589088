import { getConfiguredRegion } from "./configuredRegion"

type RegionUrls = {
  tga: string
}

type UrlsByRegion = {
  [region: string]: RegionUrls
}

const urlsByRegion: UrlsByRegion = JSON.parse(window.config.URLS_BY_REGION)

const getUrlsForRegion = (region: string): RegionUrls | undefined =>
  urlsByRegion[region]

const getConfiguredUrls = (): RegionUrls => {
  const region = getConfiguredRegion()
  if (!region) throw new Error("Region is not configured")
  const urls = getUrlsForRegion(region)
  if (!urls) throw new Error(`No urls configured for region: "${region}"`)
  return urls
}

export const isValidRegion = (region: string) => !!getUrlsForRegion(region)

export const getTgaUrl = (): string | undefined => getConfiguredUrls()?.tga
