import { ModalWindow, Header, Body } from "@teladoc/ui-components/modal"
import styled from "@emotion/styled"
import { N200 } from "../assets/colors"
import { CloseIcon as ClearIcon } from "@teladoc/ui-components/icons/index"
import type { ReactNode } from "react"

export type Props = {
  isOpen: boolean
  title: string
  body?: string
  width?: string
  minWidth?: string
  maxWidth?: string
  onClose?: () => void
  children: ReactNode
}

export const Dialog = ({
  isOpen,
  title,
  body,
  width = "90%",
  minWidth = "20rem",
  maxWidth = "35.5rem",
  onClose,
  children,
}: Props) => {
  return isOpen ? (
    <Window width={width} minWidth={minWidth} maxWidth={maxWidth} isOpen={true}>
      <Header>
        <HeaderContainer>{title}</HeaderContainer>
        <CloseButton onClick={onClose}>
          <ClearIcon />
        </CloseButton>
      </Header>
      <Body>
        <Divider />
        {body && <BodyContainer>{body}</BodyContainer>}
      </Body>
      {children}
    </Window>
  ) : (
    <></>
  )
}

const Window = styled(ModalWindow)`
  padding: 1rem 2rem 2rem 2rem !important;
  border-radius: 1rem !important;
  row-gap: 0 !important;
  min-height: 22.75rem;
  justify-content: normal !important;
`

const CloseButton = styled.button`
  background: none;
  border: none;
  text-align: right;
  cursor: pointer;
`
const Divider = styled.hr`
  border: none;
  height: 0.005rem;
  background-color: ${N200};
`

const BodyContainer = styled.div`
  padding: 0.5rem;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
`
const HeaderContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
`
