import { useCallback, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { N100 } from "@teladoc/ui-components/colors"
import { useCommClient } from "./hooks/useCommClient"
import {
  VideocallLayout,
  setRtcClient,
  whenSessionConnected,
  whenSessionEnded,
  getControlButtonWhiteList,
  setControlButtonWhiteList,
  whenSessionConnectFailed,
  whenSessionError,
} from "../ucs"
import { useStore } from "effector-react"
import { useUrlValidation } from "./hooks/useUrlValidation"
import { guestTokenStore } from "./stores/guestToken"
import { usePageNavigation } from "./hooks/usePageNagivation"
import { parseConnectFailedReason, useLanguageLine } from "./languageLine"
import { useGuestJoin, leaveCall } from "./hooks/useGuestJoin"
import { ErrorReason } from "./ErrorReason"
import { FullPageLoader } from "../components/Loader"
import { P800 } from "../assets/colors"
import { getUiProfile } from "./stores/startupConfiguration"
import { useAdminReconnectGracePeriod } from "./hooks/useAdminReconnectGracePeriod"
import { deleteInterpreterAttendee } from "../api"

export const VideocallPage = () => {
  const { goToNextPage, goToErrorPage, goToMeetingEnded } =
    usePageNavigation("videocall")
  const { commClientInitParams } = useUrlValidation()
  const { commClient } = useCommClient(commClientInitParams?.environment)
  const guestToken = useStore(guestTokenStore)
  const { joinCallParams, joinError } = useGuestJoin()
  const {
    ui_mode,
    background_replacement_color,
    block_video_quality_survey: disableSurvey,
  } = getUiProfile()
  /*
   * useRef here so we don't have to re-render the "whenSessionEnded" hook and
   * "unwatch" the event for a moment.
   */
  const leftAfterAdminGracePeriodExpired = useRef(false)

  const {
    onSessionConnected,
    onSessionConnecting,
    onSessionDisconnected,
    onSessionConnectFailed,
  } = useLanguageLine()

  useEffect(() => {
    if (joinError) {
      if (joinError === ErrorReason.CONFERENCE_ENDED) {
        goToMeetingEnded()
      } else {
        goToErrorPage(joinError)
      }
    }
  }, [joinError])

  useEffect(() => {
    const attendeeToken = commClientInitParams?.attendee.attendee_token
    const attendeeId = joinCallParams?.adapterPayload.attendee_id
    const attendanceId = joinCallParams?.adapterPayload.attendance_id
    const conferenceId = joinCallParams?.adapterPayload.conference_id
    if (!attendeeToken || !attendeeId || !attendanceId) return
    const unwatch = whenSessionEnded(() => {
      unwatch() // ensure this is only triggered once
      leaveCall({ attendeeToken, attendeeId, attendanceId })
        .then(() =>
          checkInterpreterCleanup({
            gracePeriodExpired: leftAfterAdminGracePeriodExpired.current,
            attendeeToken,
            attendeeId,
          })
        )
        .then(() => onSessionDisconnected(conferenceId))
        .then(goToNextPage)
        .catch((_e) => goToErrorPage())
    })
    return unwatch
  }, [commClientInitParams, joinCallParams])

  useEffect(() => {
    const conferenceId = joinCallParams?.adapterPayload.conference_id
    if (!conferenceId) return
    const unwatchSessionConnnectFailed = whenSessionConnectFailed((event) => {
      const message = parseConnectFailedReason(event.error)
      onSessionConnectFailed(conferenceId, message)
    })
    const unwatchSessionError = whenSessionError(({ message }) => {
      onSessionConnectFailed(conferenceId, message)
    })
    return () => {
      unwatchSessionConnnectFailed()
      unwatchSessionError()
    }
  }, [joinCallParams])

  useEffect(() => {
    const conferenceId = joinCallParams?.adapterPayload.conference_id
    if (!conferenceId) return
    const unwatch = whenSessionConnected(() => {
      onSessionConnected(conferenceId)
    })
    return unwatch
  }, [joinCallParams])

  const leaveActiveSession = useCallback(() => {
    if (!commClient) return
    for (const activeSession of commClient.sessionManager.activeSessions) {
      activeSession.leave()
    }
  }, [commClient])

  useEffect(() => {
    const onUnload = () => {
      leaveActiveSession()
    }
    return onUnload
  }, [leaveActiveSession])

  const { isAdminGracePeriodExpired } = useAdminReconnectGracePeriod()

  useEffect(() => {
    if (isAdminGracePeriodExpired) {
      // eslint-disable-next-line no-console
      console.log("Leaving session that has no Admin")
      leftAfterAdminGracePeriodExpired.current = true
      leaveActiveSession()
    }
  }, [isAdminGracePeriodExpired, leaveActiveSession])

  const startVideocall = async () => {
    onSessionConnecting(joinCallParams?.adapterPayload.conference_id)
    if (!commClient) throw new Error("comm client is not valid")
    if (!guestToken) throw new Error("guest token is not valid")
    if (!joinCallParams) throw new Error("Join call parameters are required")
    const { attendee_token: attendeeToken } =
      commClientInitParams?.attendee || {}
    if (!attendeeToken) throw new Error("attendee token is not valid")

    const { sessionJoinParams, adapterPayload } = joinCallParams

    commClient.sessionManager.startGuestJoin(sessionJoinParams, {
      adapterPayload,
      ...(background_replacement_color
        ? {
            startWithBackgroundEffect: {
              name: "replacement",
              options: {
                backgroundImage: "color",
                backgroundColor: background_replacement_color,
              },
            },
          }
        : {}),
    })
    setRtcClient(commClient)
  }

  const isReady = Boolean(commClient && guestToken && joinCallParams)

  useEffect(() => {
    if (isReady) startVideocall()
  }, [isReady])

  useEffect(() => {
    if (ui_mode !== "minimal") return
    let originalWhiteList: string[] | null

    const applyControlButtonWhiteList = async () => {
      originalWhiteList = await getControlButtonWhiteList()
      await setControlButtonWhiteList(["camera", "microphone"])
    }

    applyControlButtonWhiteList()
    return () => {
      originalWhiteList && setControlButtonWhiteList(originalWhiteList)
    }
  }, [])

  return isReady ? (
    <Container>
      <VideocallLayout
        isBlurBackgroundAllowed={false}
        disableSurvey={disableSurvey}
        isSplitViewEnabled={ui_mode !== "minimal"}
      />
    </Container>
  ) : (
    <FullPageLoader size="large" color={P800} />
  )
}

const Container = styled.div`
  background-color: ${N100};
  height: 100%;
  overflow: hidden;
`

const checkInterpreterCleanup = async (props: {
  gracePeriodExpired: boolean
  attendeeToken: string
  attendeeId: number
}) => {
  try {
    if (props.gracePeriodExpired) {
      await deleteInterpreterAttendee(props)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}
