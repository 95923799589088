import styled from "@emotion/styled"
import { ReactNode, useState } from "react"
import { N200, N700, P800, S600 } from "../assets/colors"
import { HelpIcon } from "../assets/icons/HelpIcon"
import { Dialog } from "../components/Dialog"
import { WarningBanner } from "../ucs"
import { useUnsupportedWarning } from "./hooks/useUnsupportedWarning"
import { Logo } from "./Logo"

export const PageContainer = ({
  children,
  buttonContainer,
}: {
  children: ReactNode
  buttonContainer?: ReactNode
}) => {
  const { isSupported } = useUnsupportedWarning()
  return (
    <Container>
      {isSupported !== undefined && !isSupported && <WarningBanner />}
      <Header>
        <Logo />
      </Header>
      <Body>{children}</Body>
      {buttonContainer}
      <Footer />
    </Container>
  )
}

const Footer = () => {
  const initYear = 2002
  const endYear = new Date().getFullYear()
  return (
    <FooterContent>
      <FooterTextContent>
        <FooterLeftContent>
          <BrandingContent>
            <span>
              Powered by <strong>Teladoc</strong>
            </span>
          </BrandingContent>
          <CopyrightContent>
            © {initYear}
            {endYear > initYear ? `-${endYear}` : ""} Teladoc. All rights
            reserved
          </CopyrightContent>
        </FooterLeftContent>
        <FooterRightContent>
          <PrivacyLink />
          <DividerContainer>
            <Divider />
          </DividerContainer>
          <HelpIconContainer>
            <HelpIcon />
          </HelpIconContainer>
          <HelpLink />
        </FooterRightContent>
      </FooterTextContent>
    </FooterContent>
  )
}
const PrivacyLink = () => {
  const [isOpen, setOpen] = useState(false)
  return (
    <PrivacyDiv>
      <FooterLink href="#" onClick={() => setOpen(true)}>
        Privacy &#38; Legal Policies
      </FooterLink>
      <Dialog
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title="Privacy & Legal Policies"
      >
        <LogoTitle>Teladoc</LogoTitle>
        <Link
          href="https://teladochealth.com/terms-and-conditions/"
          target="_blank"
        >
          Terms and Conditions
        </Link>
        <Link href="https://teladochealth.com/privacy-policy/" target="_blank">
          Privacy Policy
        </Link>
      </Dialog>
    </PrivacyDiv>
  )
}
const HelpLink = () => {
  const [isOpen, setOpen] = useState(false)
  return (
    <HelpDiv>
      <FooterLink href="#" onClick={() => setOpen(true)}>
        Help
      </FooterLink>
      <Dialog isOpen={isOpen} onClose={() => setOpen(false)} title="Help">
        <DialogContent>
          If you’re having difficulties joining a virtual visit or have any
          other questions regarding Teladoc services, please call Teladoc
          Support at <Link href="tel:+18008352362">1-800-835-2362</Link>.
        </DialogContent>
      </Dialog>
    </HelpDiv>
  )
}

const DividerContainer = styled.div`
  width: 8%;
`
const Divider = styled.hr`
  border: 1px solid ${N200};
  width: 1rem;
  transform: rotate(-90deg);
`
const PrivacyDiv = styled.div`
  width: 11rem;
  @media only screen and (max-width: 375px) {
    width: 8rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 768px) {
    width: 9rem;
  }
`
const HelpDiv = styled.div`
  padding-right: 1rem;
`
const HelpIconContainer = styled.div`
  width: 0.875rem;
  height: 0.875rem;
  padding-left: 0.75rem;
  padding-right: 0.35rem;
  margin-top: 0.125rem;
  @media only screen and (max-width: 375px) {
    margin-top: 0.063rem;
    width: 0.688rem;
    height: 0.688rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 768px) {
    margin-top: 0.063rem;
    width: 0.75rem;
    height: 0.75rem;
  }
`
const FooterTextContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  width: 100%;
  height: 100%;
  color: #000;
`
const FooterLeftContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
`
const BrandingContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-right: 0.25rem;
  @media only screen and (max-width: 375px) {
    font-size: 0.688rem;
    line-height: 0.75rem;
  }
`
const CopyrightContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  color: ${N700};
  @media only screen and (max-width: 375px) {
    margin-top: 0;
    font-size: 0.688rem;
    line-height: 0.75rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 768px) {
    margin-top: 0;
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
  @media only screen and (min-width: 768px) {
    margin-top: 0.125rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
`
const FooterRightContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  color: ${P800};
  font-weight: 400;
  @media only screen and (max-width: 375px) {
    font-size: 0.75rem;
    line-height: 0.75rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 768px) {
    font-size: 0.813rem;
    line-height: 0.813rem;
  }
`
const FixedHeaderFooter = styled.div`
  width: 98%;
  color: #fff;
  flex-shrink: 0;
`
const Header = styled(FixedHeaderFooter)`
  padding: 1rem 0 0 1.5rem;
`
const Body = styled.div`
  justify-content: center;
  align-self: center;
  flex-grow: 1;
  overflow-y: auto;
`
const FooterContent = styled(FixedHeaderFooter)`
  border-top: 1px solid ${N200};
  align-items: center;
  height: 3rem;
  margin-left: 1rem;
  @media only screen and (min-width: 375px) and (max-width: 768px) {
    font-size: 0.813rem;
    line-height: 0.813rem;
  }
`
const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

const LogoTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 1.5rem;
  padding-top: 1rem;
`
const Link = styled.a`
  text-decoration: underline;
  text-underline-position: under;
  padding: 1.25rem 0 0 0;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${S600};
  font-weight: 500;
`
const FooterLink = styled.a`
  color: ${P800};
  text-decoration: none;
`
const DialogContent = styled.div`
  max-width: 28.125rem;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${N700};
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 0.5rem;
`
