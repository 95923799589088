import { Component, type PropsWithChildren } from "react"
import { ErrorPage } from "../pages/ErrorPage"

type Props = PropsWithChildren

export class ErrorBoundary extends Component<Props> {
  state = { hasError: false }
  constructor(props: Props) {
    super(props)
  }
  static getDerivedStateFromError() {
    return { hasError: true }
  }
  render() {
    if (this.state.hasError) {
      return <ErrorPage />
    }
    return <>{this.props.children}</>
  }
}
