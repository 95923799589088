import { useStore } from "effector-react"
import { useEffect } from "react"
import {
  $startupConfigurationStore,
  setStartupConfiguration,
} from "../stores/startupConfiguration"
import { getStartupConfiguration } from "../../api"
import { guestTokenStore } from "../stores/guestToken"
import { applyDefaultStartupConfiguration } from "../stores/startupConfigurationDefaults"

type Props = {
  isReady: boolean
  onError: (error: unknown) => void
}

export const useStartupConfiguration = ({ isReady, onError }: Props) => {
  const startupConfiguration = useStore($startupConfigurationStore)
  const guestToken = useStore(guestTokenStore)

  useEffect(() => {
    if (!isReady) return
    if (!guestToken) return
    getStartupConfiguration(guestToken)
      .then(applyDefaultStartupConfiguration)
      .then(setStartupConfiguration)
      .catch(onError)
  }, [guestToken, isReady])

  return { startupConfiguration }
}
