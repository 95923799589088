import { $startupConfigurationStore } from "./stores/startupConfiguration"

type LanguageLineEvent = {
  type:
    | "LLS.Telehealth.Connected"
    | "LLS.Telehealth.Connecting"
    | "LLS.Telehealth.Disconnected"
    | "LLS.Telehealth.ConnectFailed"
  callId?: string
  reason?: string
}

export const useLanguageLine = () => {
  const postMessage = ({ type, callId, reason }: LanguageLineEvent) => {
    if (isStatusEventsEnabled()) {
      window.parent?.postMessage({ type, callId, reason }, "*")
    }
  }

  const onSessionConnected = (callId?: string) =>
    postMessage({ type: "LLS.Telehealth.Connected", callId })

  const onSessionConnecting = (callId?: string) =>
    postMessage({ type: "LLS.Telehealth.Connecting", callId })

  const onSessionDisconnected = (callId?: string) =>
    postMessage({ type: "LLS.Telehealth.Disconnected", callId })

  const onSessionConnectFailed = (callId?: string, reason?: string) =>
    postMessage({
      type: "LLS.Telehealth.ConnectFailed",
      callId,
      reason: reason || "Connect failed due to an unexpected error",
    })

  return {
    onSessionConnected,
    onSessionConnecting,
    onSessionDisconnected,
    onSessionConnectFailed,
  }
}

export const isStatusEventsEnabled = () => {
  const startupConfig = $startupConfigurationStore.getState()
  /*
   * Defaulting to true here means that if a failure happens before we were able
   * to retreive the startup configuration, we'll err on the side of posting an
   * event.
   */
  return startupConfig?.ui_profile.enable_status_events ?? true
}

export const parseConnectFailedReason = (
  error: unknown
): string | undefined => {
  if (error instanceof Error) {
    if (error.name === "SessionConnectionAbortedError") {
      return "The virtual visit has ended"
    } else {
      return error.message
    }
  }
  return undefined
}
