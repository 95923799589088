import styled from "@emotion/styled"

type Size = "small" | "large"
type Props = { size: Size; color: string }

export const FullPageLoader = (props: Props) => (
  <FullPageContainer>
    <Loader {...props} />
  </FullPageContainer>
)

export const Loader = ({ size, color }: Props) => {
  const { width, height, border } = sizeToDimensionMap[size]
  return (
    <LoaderContainer width={width} height={height}>
      <LoaderTrack border={border} color={color} />
      <LoaderQuarter border={border} color={color} />
    </LoaderContainer>
  )
}

const sizeToDimensionMap = {
  small: {
    width: "0.8rem",
    height: "0.8rem",
    border: "0.2rem",
  },
  large: {
    width: "4rem",
    height: "4rem",
    border: "0.5rem",
  },
}

const LoaderContainer = styled.span<{ width: string; height: string }>`
  position: relative;
  display: block;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin-left: 0.5rem;
  margin-top: -0.375rem;
`

const LoaderBase = styled.span`
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
`

const LoaderTrack = styled(LoaderBase)<{ border: string; color: string }>`
  border: ${(props) => props.border} solid ${(props) => props.color};
  opacity: 0.25;
`

const LoaderQuarter = styled(LoaderBase)<{ border: string; color: string }>`
  border: ${(props) => props.border} solid transparent;
  border-left-color: ${(props) => props.color};
  transform: translateZ(0);
  animation: Loader-loader-spinner 1s infinite linear;

  @keyframes Loader-loader-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const FullPageContainer = styled.div`
  & > span {
    margin: 50vh auto;
  }
`
