import { createEvent, createStore } from "effector"
import {
  getPreCallPayloadFromSessionStorage,
  removePreCallPayloadFromSessionStorage,
  setPreCallPayloadToSessionStorage,
} from "../stores/sessionStorage"
import { clearStores } from "../stores/clearStores"
import { IPreCallTestAdapterPayload } from "../../api/mapping/types"
import { preCallTest } from "../../api"
import { getCommClientPreCallPayload } from "../../api/mapping"
import { getAttendeeToken } from "../stores/commClientInitParams"

const setPreCallPayload = createEvent<IPreCallTestAdapterPayload>()

export const preCallPayloadStore =
  createStore<IPreCallTestAdapterPayload | null>(
    getPreCallPayloadFromSessionStorage()
  )
    .on(setPreCallPayload, (_, val) => {
      setPreCallPayloadToSessionStorage(val)
      return val
    })
    .on(clearStores, () => {
      removePreCallPayloadFromSessionStorage()
      return null
    })

export const preCallTestPreCheckHandler = async () => {
  const preCallPayload = preCallPayloadStore.getState()
  if (!preCallPayload) {
    const attendeeToken = getAttendeeToken()
    const response = await preCallTest(attendeeToken)
    const commClientResponse = getCommClientPreCallPayload(response)
    setPreCallPayload(commClientResponse)
  }
}
