import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { N200, N400 } from "../assets/colors"
import { Loader } from "../components/Loader"
import React, { CSSProperties } from "react"
import { useWhiteLabelConfig } from "../pages/stores/startupConfiguration"

export const Button = ({
  onClick,
  style,
  disabled = false,
  isLoading = false,
  children,
}: {
  onClick?: () => void
  disabled?: boolean
  isLoading?: boolean
  children: string
  style?: CSSProperties | undefined
}) => {
  const { primary_color, button_text_color } = useWhiteLabelConfig()
  return (
    <PrimaryButton
      primaryColor={primary_color}
      textColor={button_text_color}
      style={style}
      type="button"
      onClick={onClick}
      disabled={disabled || isLoading}
    >
      {children}
      {isLoading && <Loader size="small" color={N400} />}
    </PrimaryButton>
  )
}

type PrimaryButtonProps = {
  primaryColor: string
  textColor: string
}

const PrimaryButton = styled.button(
  ({ primaryColor, textColor }: PrimaryButtonProps) => css`
    background-color: ${primaryColor};
    width: 8.625rem;
    position: relative;
    color: ${textColor};
    cursor: pointer;
    border-radius: 9999px;
    height: 3rem;
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    border: 0;
    &:disabled {
      cursor: default;
      background-color: ${N200};
      color: ${N400};
    }
    @media only screen and (max-width: 415px) {
      width: 100%;
    }
    @media only screen and (min-width: 416px) {
      width: 8.625rem;
    }
  `
)
