import { Suspense } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { InvalidUrlPage } from "./pages/InvalidUrlPage"
import { LandingPage } from "./pages/LandingPage"
import { VideocallPage } from "./pages/VideocallPage"
import { MeetingEndedPage } from "./pages/MeetingEndedPage"
import { WelcomePage } from "./pages/WelcomePage"
import { ThankYouPage } from "./pages/ThankYouPage"
import { ErrorPage } from "./pages/ErrorPage"
import { PreCallTestPage } from "./pages/PreCallTestPage"
import { FullPageLoader } from "./components/Loader"
import { GlobalStyles } from "./assets/GlobalStyles"
import { ErrorBoundary } from "./components/ErrorBoundary"

export const App = () => (
  <ErrorBoundary>
    <Suspense fallback={<FullPageLoader size="large" color="#000" />}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="*" element={<InvalidUrlPage />} />
          <Route path="/join" element={<LandingPage />} />
          <Route path="/join/:guestToken" element={<LandingPage />} />
          <Route path="/welcome" element={<WelcomePage />} />
          <Route path="/precall" element={<PreCallTestPage />} />
          <Route path="/videocall" element={<VideocallPage />} />
          <Route path="/meetingEnded" element={<MeetingEndedPage />} />
          <Route path="/thankyou" element={<ThankYouPage />} />
          <Route path="/error/:reasonCode" element={<ErrorPage />} />
        </Routes>
      </Router>
    </Suspense>
  </ErrorBoundary>
)
