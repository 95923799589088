import type { StartupConfiguration } from "./startupConfiguration"
import {
  CommClientInitParams,
  CommClientJoinCallParams,
  IPreCallTestAdapterPayload,
} from "../../api/mapping/types"

export type ResponseType =
  | "CommClientInitParams"
  | "CommClientJoinCallParams"
  | "PreCallPayload"
  | "GuestToken"
  | "StartupConfiguration"
  | "Region"

export type PayloadType =
  | CommClientInitParams
  | CommClientJoinCallParams
  | IPreCallTestAdapterPayload
  | string
  | StartupConfiguration

export const setInitParamsToSessionStorage = (payload: CommClientInitParams) =>
  setItem("CommClientInitParams", payload)

export const getInitParamsFromSessionStorage =
  (): CommClientInitParams | null => getItem("CommClientInitParams")

export const removeInitParamsFromSessionStorage = () =>
  removeItem("CommClientInitParams")

export const setJoinParamsToSessionStorage = (
  payload: CommClientJoinCallParams
) => setItem("CommClientJoinCallParams", payload)

export const getJoinParamsFromSessionStorage =
  (): CommClientJoinCallParams | null => getItem("CommClientJoinCallParams")

export const setPreCallPayloadToSessionStorage = (
  payload: IPreCallTestAdapterPayload
) => setItem("PreCallPayload", payload)

export const getPreCallPayloadFromSessionStorage =
  (): IPreCallTestAdapterPayload | null => getItem("PreCallPayload")

export const removePreCallPayloadFromSessionStorage = () =>
  removeItem("PreCallPayload")

export const removeJoinParamsFromSessionStorage = () =>
  removeItem("CommClientJoinCallParams")

export const setStartupConfigurationToSessionStorage = (
  payload: StartupConfiguration
) => setItem("StartupConfiguration", payload)

export const getStartupConfigurationFromSessionStorage =
  (): StartupConfiguration | null => getItem("StartupConfiguration")

export const removeStartupConfigurationFromSessionStorage = () =>
  removeItem("StartupConfiguration")

export const setGuestTokenToSessionStorage = (payload: string) =>
  setItem("GuestToken", payload)

export const getGuestTokenFromSessionStorage = (): string | null =>
  getItem("GuestToken")

export const removeGuestTokenFromSessionStorage = () => removeItem("GuestToken")

export const setRegionToSessionStorage = (payload: string) =>
  setItem("Region", payload)

export const getRegionFromSessionStorage = (): string | null =>
  getItem("Region")

export const removeRegionFromSessionStorage = () => removeItem("Region")

const setItem = (type: ResponseType, jsonPayload: PayloadType) => {
  window.sessionStorage.setItem(type, JSON.stringify(jsonPayload))
}

const removeItem = (type: ResponseType) => {
  window.sessionStorage.removeItem(type)
}

const getItem = <T>(type: ResponseType): T | null => {
  const response = window.sessionStorage.getItem(type)
  return response ? JSON.parse(response) : null
}
