import "./env" //NOTE: This always has to be the first thing that is imported
import { hot } from "react-hot-loader/root"
import { App } from "./App"
import { createRoot } from "react-dom/client"
import Modal from "react-modal"

const appEl = document.getElementById("wrap")
if (appEl) {
  Modal.setAppElement(appEl)
  const HotApp = hot(App)
  const root = createRoot(appEl)
  root.render(<HotApp />)
}
