export const ErrorIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8C16 3.6129 12.3871 0 8 0ZM12.5484 3.45161C14.9032 5.80645 15.0323 9.48387 13.0645 12L4 2.93548C6.51613 0.967742 10.1935 1.09677 12.5484 3.45161ZM3.41935 12.5806C1.06452 10.2258 0.935484 6.54839 2.90323 4.03226L11.9677 13.0968C9.45161 15.0645 5.77419 14.9355 3.41935 12.5806Z"
        fill="#CD000A"
      />
    </svg>
  )
}
