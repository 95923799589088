import { useEffect, useState } from "react"
import {
  getMembersList,
  whenMembersUpdated,
  whenSessionConnected,
} from "../../ucs"
import type { VideoCallMember } from "@teladoc/ucs-client-react"
import { getStartupConfiguration } from "../stores/startupConfiguration"

export const useAdminReconnectGracePeriod = () => {
  const [isSessionConnected, setSessionConnected] = useState(false)
  const [adminCount, setAdminCount] = useState<number>()
  const [isAdminGracePeriodExpired, setAdminGracePeriodExpired] =
    useState(false)

  useEffect(() => {
    const unwatch = whenSessionConnected(() => setSessionConnected(true))
    return unwatch
  }, [])

  useEffect(() => {
    if (!isSessionConnected || !isMonitoringEnabled()) return
    const onMembersUpdated = (members: VideoCallMember[]) => {
      setAdminCount(countAdmins(members))
    }
    // fetch an initial list independent of update events
    const initializeMembersAsync = async () => {
      const initialMembers = await getMembersList()
      onMembersUpdated(initialMembers)
    }
    initializeMembersAsync()
    // listen for update events
    const unwatch = whenMembersUpdated(onMembersUpdated)
    return unwatch
  }, [isSessionConnected])

  useEffect(() => {
    if (adminCount === undefined) return // disabled or not yet initialized
    if (adminCount > 0) return
    const timeout = setTimeout(() => {
      setAdminGracePeriodExpired(true)
    }, getGracePeriodSeconds() * 1000)
    return () => {
      clearTimeout(timeout)
    }
  }, [adminCount])

  return { isAdminGracePeriodExpired }
}

export const countAdmins = (members: VideoCallMember[]) => {
  // the local participant should be in the call before we start monitoring
  if (!members.length) {
    return undefined
  } else {
    return members.reduce(
      (adminCount, { isProvider }) =>
        isProvider ? adminCount + 1 : adminCount,
      0
    )
  }
}

const isMonitoringEnabled = () =>
  getStartupConfiguration().startup_options.monitor_admin_presence

const getGracePeriodSeconds = () =>
  getStartupConfiguration().startup_options.admin_reconnect_grace_period
