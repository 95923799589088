import type {
  UIProfile,
  StartupOptions,
  StartupConfiguration,
  WhiteLabelConfig,
} from "./startupConfiguration"
import type {
  ApiStartupOptions,
  ApiStartupConfiguration,
  ApiUIProfile,
  ApiWhiteLabelConfig,
} from "../../api/types"
import { P800 } from "../../assets/colors"

const applyDefaultStartupOptions = ({
  skip_browser_check,
  monitor_admin_presence,
  admin_reconnect_grace_period,
}: ApiStartupOptions): StartupOptions => ({
  skip_browser_check: skip_browser_check ?? false,
  monitor_admin_presence: monitor_admin_presence ?? false,
  admin_reconnect_grace_period: admin_reconnect_grace_period ?? 0,
})

const applyDefaultUIProfile = ({
  ui_mode,
  enable_status_events,
  background_replacement_color,
  offer_precall_test,
  collect_user_name,
  block_video_quality_survey,
}: ApiUIProfile): UIProfile => ({
  ui_mode: ui_mode ?? "full",
  enable_status_events: enable_status_events ?? false,
  background_replacement_color: background_replacement_color ?? undefined,
  offer_precall_test: offer_precall_test ?? true,
  collect_user_name: collect_user_name ?? true,
  block_video_quality_survey: block_video_quality_survey ?? false,
})

export const applyDefaultWhiteLabelConfig = ({
  logo_url,
  primary_color,
  button_text_color,
}: ApiWhiteLabelConfig): WhiteLabelConfig => ({
  logo_url,
  primary_color: primary_color || P800,
  button_text_color: button_text_color || "#fff",
})

export const applyDefaultStartupConfiguration = ({
  startup_options,
  ui_profile,
  white_label_config,
}: ApiStartupConfiguration): StartupConfiguration => ({
  startup_options: applyDefaultStartupOptions(startup_options ?? {}),
  ui_profile: applyDefaultUIProfile(ui_profile ?? {}),
  white_label_config: applyDefaultWhiteLabelConfig(white_label_config ?? {}),
})
