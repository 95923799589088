import styled from "@emotion/styled"
import { PageContainer } from "./PageContainer"
import { N900 } from "../assets/colors"
import { useLanguageLine } from "./languageLine"
import { useEffect } from "react"

export const MeetingEndedPage = () => {
  const { onSessionConnectFailed } = useLanguageLine()

  useEffect(() => {
    onSessionConnectFailed(undefined, "The virtual visit has ended")
  }, [])

  return (
    <PageContainer>
      <Container>
        <HeaderContainer>
          <div>
            <h1>It looks like the virtual visit has ended</h1>
          </div>
        </HeaderContainer>
        <DetailsContainer>
          <div>
            <p>
              Contact the person who invited you to this visit if you need to
              still join.
            </p>
          </div>
        </DetailsContainer>
      </Container>
    </PageContainer>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: top;
  flex-direction: column;
  height: 55vh;
  margin-left: 1rem;
  margin-right: 1rem;
`
const HeaderContainer = styled.div`
  font-weight: 700;
  display: flex;
  color: ${N900};
  margin-top: 1.75rem;
  align-items: center;
  font-weight: 700;
  text-align: left;
  font-size: 1rem;
  line-height: 2.5rem;
`

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: top;
  text-align: left;
`

const DetailsContainer = styled(ItemContainer)`
  font-size: 1.125rem;
  line-height: 1.75rem;
`
