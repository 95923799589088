import React from "react"

export const HelpIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.01481 3.5C5.92106 3.5 5.21012 3.95117 4.64958 4.75781C4.55387 4.89453 4.58122 5.09961 4.73161 5.19531L5.3195 5.64648C5.45622 5.75586 5.6613 5.72852 5.77067 5.5918C6.11247 5.16797 6.37223 4.92188 6.90544 4.92188C7.32926 4.92188 7.8488 5.18164 7.8488 5.5918C7.8488 5.90625 7.58903 6.05664 7.17887 6.28906C6.68669 6.5625 6.04411 6.9043 6.04411 7.75195V7.875C6.04411 8.06641 6.1945 8.20312 6.37223 8.20312H7.37028C7.54801 8.20312 7.6984 8.06641 7.6984 7.875V7.80664C7.6984 7.21875 9.40739 7.19141 9.40739 5.60547C9.40739 4.41602 8.16325 3.5 7.01481 3.5ZM6.87809 8.61328C6.34489 8.61328 5.92106 9.03711 5.92106 9.55664C5.92106 10.0762 6.34489 10.5 6.87809 10.5C7.39762 10.5 7.82145 10.0762 7.82145 9.55664C7.82145 9.03711 7.39762 8.61328 6.87809 8.61328Z"
        fill="#5B2F91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 12.6875C10.1411 12.6875 12.6875 10.1411 12.6875 7C12.6875 3.85888 10.1411 1.3125 7 1.3125C3.85888 1.3125 1.3125 3.85888 1.3125 7C1.3125 10.1411 3.85888 12.6875 7 12.6875ZM7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"
        fill="#5B2F91"
      />
    </svg>
  )
}
