import { createEvent, createStore } from "effector"
import {
  getRegionFromSessionStorage,
  removeRegionFromSessionStorage,
  setRegionToSessionStorage,
} from "../pages/stores/sessionStorage"

export const setConfiguredRegion = createEvent<string | null>()

export const $configuredRegion = createStore<string | null>(
  getRegionFromSessionStorage()
).on(setConfiguredRegion, (_, region) => {
  if (region) {
    setRegionToSessionStorage(region)
  } else {
    removeRegionFromSessionStorage()
  }
  return region
})

export const getConfiguredRegion = $configuredRegion.getState
