import { getTgaUrl } from "../regions"
import { StatusCodeError } from "./statusCodeError"
import {
  ValidateUrlResponse,
  GuestJoinResponse,
  PreCallTestResponse,
  ApiStartupConfiguration,
} from "./types"

type RequestMethod = "GET" | "POST" | "PUT" | "DELETE"

type RequestProps = {
  path: string
  token?: string
  body?: string
}

export const getStartupConfiguration = async (guestToken: string) =>
  sendRequest<ApiStartupConfiguration>({
    method: "GET",
    path: `startup/configuration/${guestToken}`,
  })

export const validateUrl = async (url: string) => {
  const body = JSON.stringify({
    validate_guest_url: {
      url,
    },
  })
  return await postRequest<ValidateUrlResponse>({
    path: `guest/validate/url`,
    body,
  })
}

export const updateGuestName = async ({
  attendeeToken,
  guestToken,
  guestName,
}: {
  attendeeToken: string
  guestToken: string
  guestName: string
}) => {
  const body = JSON.stringify({
    update_name: {
      guest_user_id: guestToken,
      name: guestName,
    },
  })
  await send({
    path: `guest/name`,
    body,
    token: attendeeToken,
    method: "PUT",
  })
  return guestName
}

export const preCallTest = (attendeeToken: string) =>
  postRequest<PreCallTestResponse>({
    path: `precalltest`,
    token: attendeeToken,
  })

export const updatePreCallTestResult = async ({
  attendeeToken,
  preCallTestId,
  result,
}: {
  attendeeToken: string
  preCallTestId: string
  result: string
}) => {
  const body = JSON.stringify({
    precall_test: {
      result,
    },
  })
  await send({
    path: `precalltest/${preCallTestId}`,
    body,
    token: attendeeToken,
    method: "PUT",
  })
}

export const joinCall = async (attendeeToken: string, guestToken: string) =>
  await postRequest<GuestJoinResponse>({
    path: `guest/join/${guestToken}`,
    token: attendeeToken,
  })

const buildDeleteAttendancePath = ({
  attendeeId,
  attendanceId,
}: {
  attendeeId: number
  attendanceId: number
}) => `guest/${attendeeId}/attendance?id=${attendanceId}`

type DeleteAttendanceProps = {
  attendeeToken: string
  attendeeId: number
  attendanceId: number
}

export const deleteAttendance = async (
  props: DeleteAttendanceProps & {
    keepalive?: boolean
  }
) => {
  const { attendeeToken, keepalive } = props
  await send({
    method: "DELETE",
    path: buildDeleteAttendancePath(props),
    token: attendeeToken,
    keepalive,
  })
}

export const deleteInterpreterAttendee = async ({
  attendeeToken,
  attendeeId,
}: {
  attendeeToken: string
  attendeeId: number
}) => {
  await send({
    method: "DELETE",
    path: `interpreter/${attendeeId}`,
    token: attendeeToken,
  })
}

/**
 * Synchronously DELETE the attendance record for this attendee.  This
 * is only useful in Firefox where the browser doesn't support the
 * "keepalive" flag on browser close.
 *
 * Note that even when performed synchronously on window unload, it
 * only succeeds if the request finishes very quickly on browser close.
 * On page refresh, Firefox always seems to wait for it to finish before
 * refreshing the page.
 */
export const deleteAttendanceSync = (props: DeleteAttendanceProps) => {
  const path = buildDeleteAttendancePath(props)
  const url = `${getTgaUrl()}/api/v1/${path}`
  const client = new XMLHttpRequest()
  client.open("DELETE", url, false)
  client.setRequestHeader("Authorization", `Bearer ${props.attendeeToken}`)
  client.send()
}

const postRequest = async <T>(props: RequestProps): Promise<T> =>
  await sendRequest({ ...props, method: "POST" })

const sendRequest = async <T>(
  props: RequestProps & { method: RequestMethod }
): Promise<T> => {
  const response = await send({ ...props })
  return await response.json()
}

const send = async ({
  path,
  token,
  body,
  method,
  keepalive,
}: {
  path: string
  token?: string
  body?: string
  method: RequestMethod
  keepalive?: boolean
}): Promise<Response> => {
  const response = await fetch(`${getTgaUrl()}/api/v1/${path}`, {
    method,
    headers: {
      ...(body ? { "Content-Type": "application/json" } : {}),
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
    },
    body,
    keepalive,
  })
  if (!response.ok) {
    throw new StatusCodeError(
      response.status,
      `Request failed: [${response.status} - ${response.statusText}]`
    )
  }
  return response
}
