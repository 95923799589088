import { createEvent, createStore } from "effector"
import { clearStores } from "./clearStores"
import {
  getGuestTokenFromSessionStorage,
  removeGuestTokenFromSessionStorage,
  setGuestTokenToSessionStorage,
} from "./sessionStorage"

export const setGuestToken = createEvent<string>()

export const guestTokenStore = createStore<string | null>(
  getGuestTokenFromSessionStorage()
)
  .on(setGuestToken, (_, value) => {
    setGuestTokenToSessionStorage(value)
    return value
  })
  .on(clearStores, () => {
    removeGuestTokenFromSessionStorage()
    return null
  })

export const getGuestToken = () => {
  const guestToken = guestTokenStore.getState()
  if (!guestToken) throw new Error("guest token is not valid")
  return guestToken
}
